import { Row, Col, Button, Form, Collapse, DatePicker, message, Spin, Space, Table, Pagination } from 'antd'
import { FileExcelOutlined, CheckOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, APPJSON, LOADING, UNIDATEFORMAT, UNIDATETIMEFORMAT, PAGINATIONSIZE, PAGESIZE, DATEFORMAT } from "../Common/SystemParameter"
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM, getUserSiteName } from "../Common/UserSession"
import { reportError, stringNumberSorter, numberWithCommas } from "../Common/Utility"
import { formLayout_2Columns } from "../Common/Layout"
import moment from 'moment'
import dayjs from 'dayjs'

const { Panel } = Collapse

//----------
// Component
//----------
const FeedingPlanTable = () => {
    const FileDownload = require('js-file-download')
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    
    const [feedingWeek, setFeedingWeek] = useState(null)
    const [feedingEntriesDataSource, setFeedingEntriesDataSource] = useState([])
    const [isLoading, setIsLoading] = useState(false)
        
    //---------------
    // Search feeding
    //---------------
    const searchFeeding = () => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}feeding/plan/`, {
            params: { 
                site: getUserSiteId(),
                feeding_week: moment(feedingWeek).format(UNIDATEFORMAT),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")) * 10,
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search planned feeding record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        let key = 0

        response.data.forEach( feeding => {
            key++
            data.push({
                key: key,
                species: feeding.species_name,
                storage: feeding.storage_name,
                fishQuantity: feeding.total_quantity,
                averageWeightKg: (feeding.average_weight_gram / 1000).toFixed(3),
                totalWeightKg: numberWithCommas(feeding.total_weight_kg),
                feedSize: feeding.feed_size,
                feedType: feeding.feed_type,
                biomassPercentage: feeding.biomass_percentage,
                feedingWeightKg: feeding.each_feeding_weight_kg,
                feedingDate: moment(feeding.feeding_date).format(UNIDATEFORMAT),
                feedingDateFormatted: moment(feeding.feeding_date).format(DATEFORMAT)
            })
        })
        
        setFeedingEntriesDataSource(data)
    }

    //--------------------------
    // Download excel
    //--------------------------
    const downloadFeedingPlan = (mode) => {
        if(feedingWeek == null) {
            message.warn("Feeding week is required.")
            return
        }

        setDisableButton("disabled")
        setIsLoading(true)
                
        axios.get(`${ACISAPIURL}feeding/plan/download/`, {
            params: { 
                site: getUserSiteId(),
                feeding_week: moment(feedingWeek).format(UNIDATEFORMAT),
            },
            responseType: 'blob', // Important
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                FileDownload(response.data, `Feeding Plan ${getUserSiteName()} ${now}.xlsx`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download feeding plan.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const saveFeedingDone = (e, record) => {
        setIsLoading(true)
        
        axios.post(`${ACISAPIURL}feeding/create/planned/`, {
            site: getUserSiteId(),
            storage_name: record.storage,
            feeding_type: record.feedType,
            feeding_weight_kg: record.feedingWeightKg,
            feeding_date: record.feedingDate,
            fish_quantity: record.fishQuantity,
        }, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info("Feeding record saved.")
            // Hide saved.
            setFeedingEntriesDataSource(feedingEntriesDataSource.filter(entry => entry.key != record.key))
        })
        .catch( error => {
            reportError(error, `Failed to save feeding record.`)
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }
    
    //----------
    // On search
    //----------
    const onSearch = () => {
        if(feedingWeek == null) {
            message.warn("Feeding week is required.")
            return
        }

        searchFeeding(1)
    }
    
    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //---------------------
    // On feeding week change
    //---------------------
    const onFeedingWeekChange = (week) => {
        setFeedingWeek(week)
    }

    const disabledDate = (current) => {
        // Can not select days before today.
        //return current && current < dayjs().subtract(1, 'days').endOf('day');
        return current && current < dayjs().subtract(14, 'days').endOf('day');
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Species', dataIndex: 'species', key: 'species', sorter: (a, b) => a.species.localeCompare(b.species) },
        { title: 'Storage', dataIndex: 'storage', key: 'storage', align: 'center', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        { title: 'Fish Quantity', dataIndex: 'fishQuantity', key: 'fishQuantity', align: "right", sorter: (a, b) => stringNumberSorter(a.fishQuantity, b.fishQuantity) },
        { title: 'Average Weight (Kg)', dataIndex: 'averageWeightKg', key: 'averageWeightKg', align: "right", sorter: (a, b) => a.averageWeightKg - b.averageWeightKg },
        { title: 'Total Weight (Kg)', dataIndex: 'totalWeightKg', key: 'totalWeightKg', align: "right", sorter: (a, b) => a.totalWeightKg - b.totalWeightKg },
        { title: 'Feed Size', dataIndex: 'feedSize', key: 'feedSize', align: "center", sorter: (a, b) => a.feedSize.localeCompare(b.feedSize) },
        { title: 'Feed Type', dataIndex: 'feedType', key: 'feedType', sorter: (a, b) => a.feedType.localeCompare(b.feedType) },
        { title: 'Biomass Percentage (%)', dataIndex: 'biomassPercentage', key: 'biomassPercentage', align: "center", sorter: (a, b) => a.biomassPercentage - b.biomassPercentage },
        { title: 'Feeding Weight (Kg)', dataIndex: 'feedingWeightKg', key: 'feedingWeightKg', align: "right", sorter: (a, b) => a.feedingWeightKg - b.feedingWeightKg },
        { title: 'Feeding Date', dataIndex: 'feedingDateFormatted', key: 'feedingDateFormatted', align: "center", sorter: (a, b) => a.feedDateFormatted.localeCompare(b.feedingDateFormatted) },
        { title: 'Fed?', align: 'center', 
            render: (record) => {
                return <Button type="primary" htmlType="button" style={{margin: "0px"}} onClick={(e) => saveFeedingDone(e, record)} icon={<CheckOutlined />} />
            }
        },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Select Feeding Week" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <Form.Item name="feedingWeek" label="Feeding Week"
                                rules={[
                                    { required: true, message: "Feeding week is required." },
                                ]}>
                                <DatePicker onChange={onFeedingWeekChange} picker="week" style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}
                                    disabledDate={disabledDate} allowClear={false}/>
                            </Form.Item>

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button type="primary" htmlType="button" onClick={downloadFeedingPlan} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={feedingEntriesDataSource} 
            pagination={{
                    defaultPageSize: PAGESIZE,
                    size: PAGINATIONSIZE,
                    position: ['bottomCenter'],
                    showTotal: showTotal,
            }}       
        />

        <Row><Col><Space><div /></Space></Col></Row>

        </Spin>
        </>
    )
}

export default FeedingPlanTable