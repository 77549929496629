import { Row, Col, Space, Button, Collapse, message, Spin } from 'antd'
import { FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, APPJSON, LOADING, UNIDATETIMEFORMAT } from "../Common/SystemParameter"
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM, getUserSiteName} from "../Common/UserSession"
import { reportError } from "../Common/Utility"
import moment from 'moment'

const { Panel } = Collapse

//----------
// Component
//----------
const SurvivalRateReportTable = () => {
    const FileDownload = require('js-file-download')
    const [isLoading, setIsLoading] = useState(false)
    
    //--------------------------
    // Download excel
    //--------------------------
    const downloadReport = () => {
        setIsLoading(true)
                
        axios.get(`${ACISAPIURL}inventory/survivalrate/download/`, {
            params: { 
                site: getUserSiteId(),
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 3),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Download returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                FileDownload(response.data, `Survival Rate by Batch ${now}.xlsx`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download report.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
    }, [])

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Download Survival Rate Report" key="1">
                        {/* <Form form={form} {...formLayout_2Columns}> */}
                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={() => downloadReport()} loading={isLoading} icon={<FileExcelOutlined />}>
                                        Download
                                    </Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        {/* </Form> */}
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>
        </Spin>
        </>
    )
}

export default SurvivalRateReportTable